<template>
  <v-container fluid style="background-color:#fff;">
    <v-container style="margin-top:0px;padding-top:0;">
      <v-row class="mt-5 d-print-none pl-1 pr-1">
        <h3>Print KERRY แบบมี บาร์โค้ด</h3>
        <p class="blue--text text--darken-1 body-2">
          <v-icon small color="blue">info</v-icon> ออเดอร์ที่จะสั่ง
          <b>PRINT</b> ได้ต้องมีสถานะเป็น
          <b class="red--text">KERRY-EXPORTED</b> ก่อนเท่านั้น <b>หลังจาก</b> PRINT
          แล้ว ให้กด <b>อัพเดตสถานะ</b> เป็น <b class="red--text">KERRY-Printed</b> อัตโนมัติ
        </p>
      </v-row>
      <v-row class="caption d-md-none d-lg-none pl-1 pr-1 d-print-none">
        <v-col>
          <b>ขั้นตอน</b><br />
          กด 2. สั่งพิมพ์ <br />
          กด <b>3.</b> อัพเดต หลังจากปริ้นท์เสร็จ <br />
          กด <b>4.</b> เป็นอันเสร็จและกลับไปหน้าก่อนหน้า
        </v-col>
      </v-row>
      <v-row class="pl-1 pr-1 d-print-none">
        <v-btn class="mt-1" small @click="$router.go(-1)">กลับ</v-btn>
      </v-row>
      <v-stepper non-linear class="mt-5 mb-10 d-print-none">
        <v-stepper-header>
          <v-stepper-step editable step="1" color="red darken-3">
            รายการ
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step editable step="2" @click="doPrint" color="green">
            สั่งพิมพ์
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            editable
            step="3"
            @click="updateStatus"
            color="yellow darken-3"
          >
            อัพเดตสถานะ KERRY-Printed
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            editable
            step="4"
            @click="$router.go(-1)"
            color="blue darken-3"
          >
            เสร็จ
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <div v-for="(order, index) in orders" :key="index">
        <template v-if="index % 5 == 0">
          <div class="page-break"></div>
        </template>

        <v-row
          class="myfontprint"
          style="border-bottom:1px dashed grey; height:253px; width:750px;"
        >
          <v-col cols="7" style="border-right:1px dashed grey;" class="pa-1">
            <v-row class="mt-1">
              <v-col cols="12 pt-0">
                <v-row class="ml-3">
                  <v-col class="pb-2 pt-7 text-center">
                    <h2 class="font-weight-medium" v-if="order.tracking">{{order.tracking}}</h2>
                    <h2 class="font-weight-medium" v-else>{{order.id | KERRYTrackingFormat}}</h2>
                  </v-col>
                </v-row>

                <div class="pt-5">
                  <div class="d-flex">
                    <div>
                      <img
                        class="mr-2 mt-2"
                        :src="order.branch.image | imageUrl"
                        height="40"
                      />
                    </div>
                    <div>
                      <div class="sender_info">
                        ผู้ส่ง o {{ order.id }} {{ order.createdAt | moment("YYYY-MM-DD") }}
                      </div>
                      <div
                        class="sender_info"
                        v-if="order.sender_info"
                        v-html="order.sender_info"
                      ></div>
                      <div
                        class="sender_info"
                        v-else
                        v-html="order.branch.detail"
                      ></div>
                    </div>
                    <div class="text-right ml-auto pb-0 pt-0 pr-5">
                        <b>KERRY</b>
                      <template v-if="order.is_cod == PAYMENTMETHOD.COD">
                        <div style="font-size:18px;color:green;">
                          <b>COD {{ order.amount | currency("", 0) }}</b>
                        </div>
                        <div class="md-2" style="font-size:14px;color:black;">
                          <b>เก็บเงินปลายทาง</b>
                        </div>
                      </template>
                    </div>
                  </div>

                  <div class="mt-3 grey--text text--darken-4" style="font-size:12px;">
                    ผู้รับ <span style="font-size:17px;">{{ order.name }}</span> โทร
                      {{ order.tel1 }}<br />
                    {{ order.address_info }}<br />
                    {{ order.address_info2 }}
                    <div v-if="order.remark_customer" class="red--text">** {{order.remark_customer}}</div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="pa-2 mt-3">
            <span style="font-size:13px;"
              >ยอด {{ order.amount | currency("", 0) }}</span
            >
            <ul class="ullistdetail">
              <li v-for="(prd, pindex) in order.products" :key="pindex">
                <img :src="prd.product_image | imageUrl" aspect-ratio="1" />
                <span
                  >
                  <b
                    class="font-weight-bold"
                    style="font-size:18px;"
                    >{{ prd.qty }}</b
                  > <b style="font-size:18px;">x</b> 
                  {{ prd.product_name }} {{ prd.product_subname }}
                </span>
              </li>
            </ul>
            <div class="pt-1 red--text" v-if="order.remark_admin">
              <u>หมายเหตุ</u>: {{ order.remark_admin }}
            </div>
          </v-col>
        </v-row>
        <!-- <v-row :class="index % 3 == 0 ? 'page-break' : 'page-break-no'"></v-row> -->
      </div>

      <!-- กรณีที่มี รายการสินค้า มากกว่า 5 ใน 1 ออเดอร์ แบ่ง page หน้าละ 2 ออเดอร์ -->
      <div v-for="(order, index) in orderMoreItems" :key="index + 'b'">
        <template v-if="index % 3 == 0">
          <div class="page-break"></div>
        </template>

        <v-row
          class="myfontprint"
          style="border-bottom:1px dashed grey; min-height:400px; width:750px;"
        >
          <v-col cols="7" style="border-right:1px dashed grey;" class="pa-1">
            <v-row class="mt-1">
              <v-col cols="12 pt-0">
                <v-row class="ml-3">
                  <v-col class="pb-2 pt-7 text-center">
                    <!-- <barcode
                      v-if="order.tracking"
                      :value="order.tracking"
                      format="CODE128"
                      height="37"
                      width="2"
                      fontSize="14"
                    >
                      - ไม่สามารถ gen barcode ได้-
                    </barcode>
                    <barcode
                      v-else
                      :value="order.id | KERRYTrackingFormat"
                      format="CODE128"
                      height="37"
                      width="2"
                      fontSize="14"
                    >
                      - ไม่สามารถ gen barcode ได้-
                    </barcode> -->
                    <h2 class="font-weight-medium" v-if="order.tracking">{{order.tracking}}</h2>
                    <h2 class="font-weight-medium" v-else>{{order.id | KERRYTrackingFormat}}</h2>
                  </v-col>
                </v-row>

                <div class="pt-5">
                  <div class="d-flex">
                    <div>
                      <img
                        class="mr-2 mt-2"
                        :src="order.branch.image | imageUrl"
                        height="40"
                      />
                    </div>
                    <div>
                      <div class="sender_info">
                        ผู้ส่ง o {{ order.id }} {{ order.createdAt | moment("YYYY-MM-DD") }}
                      </div>
                      <div
                        class="sender_info"
                        v-if="order.sender_info"
                        v-html="order.sender_info"
                      ></div>
                      <div
                        class="sender_info"
                        v-else
                        v-html="order.branch.detail"
                      ></div>
                    </div>
                    <div class="text-right ml-auto pb-0 pt-0 pr-5">
                        <b>KERRY</b>
                      <template v-if="order.is_cod == PAYMENTMETHOD.COD">
                        <div style="font-size:18px;color:green;">
                          <b>COD {{ order.amount | currency("", 0) }}</b>
                        </div>
                        <div class="md-2" style="font-size:14px;color:black;">
                          <b>เก็บเงินปลายทาง</b>
                        </div>
                      </template>
                    </div>
                  </div>

                  <div class="mt-3 grey--text text--darken-4" style="font-size:12px;">
                    ผู้รับ <span style="font-size:17px;">{{ order.name }}</span> โทร
                      {{ order.tel1 }}<br />
                    {{ order.address_info }}<br />
                    {{ order.address_info2 }}
                    <div v-if="order.remark_customer" class="red--text">** {{order.remark_customer}}</div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="pa-2 mt-3">
            <span style="font-size:13px;"
              >ยอด {{ order.amount | currency("", 0) }}</span
            >
            <ul class="ullistdetail">
              <li v-for="(prd, pindex) in order.products" :key="pindex + 'c'">
                <img :src="prd.product_image | imageUrl" aspect-ratio="1" />
                <span
                  ><b
                    class="font-weight-bold"
                    style="font-size:18px;"
                    >{{ prd.qty }}</b
                  > <b style="font-size:18px;">x</b> 
                  {{ prd.product_name }} {{ prd.product_subname }}
                </span>
              </li>
            </ul>
            <div class="pt-1 red--text" v-if="order.remark_admin">
              <u>หมายเหตุ</u>: {{ order.remark_admin }}
            </div>
          </v-col>
        </v-row>
        <!-- <v-row :class="index % 3 == 0 ? 'page-break' : 'page-break-no'"></v-row> -->
      </div>

      <v-snackbar
        v-model="snackbar.active"
        bottom
        color="blue darken-2"
        :timeout="3000"
      >
        <v-icon dark small class="mr-1">check_circle</v-icon>
        <b>{{ snackbar.msg }}</b>
        <v-btn dark text @click="snackbar.active = false">
          ปิด
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="snackbarFaild.active"
        bottom
        color="error"
        :timeout="3000"
      >
        <v-icon dark small class="mr-1">cancel</v-icon>
        <b>{{ snackbarFaild.msg }}</b>
        <v-btn dark text @click="snackbarFaild.active = false">
          ปิด
        </v-btn>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import api, { ORDERSTATUS, PAYMENTMETHOD } from "@/services/api";
import VueBarcode from "vue-barcode";
import _ from "lodash";

export default {
  name: "PrintOrderKerryTracking",
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      PAYMENTMETHOD,
      snackbar: {
        active: false,
        msg: "",
      },
      snackbarFaild: {
        active: false,
        msg: "",
      },
      orders: [],
      orderMoreItems: [],
      ordersPrint: [],
    };
  },
  mounted() {
    // console.log(this.$store.getters["itemsSelected"]);
    this.getItemsData();
  },
  methods: {
    async updateStatus() {
      if (confirm("คุณต้องการอัพเดตสถานะใช่หรือไม่")) {
        let itemsSelected = this.ordersPrint;
        // let itemsSelected = this.$store.getters["itemsSelected"];
        if (itemsSelected) {
          let ids = _.map(itemsSelected, (item) => {
            return item.id;
          });

          let formData = new FormData();
          formData.append("items", JSON.stringify(ids));
          formData.append("status", ORDERSTATUS.KERRYPRINTED);
          let result = await api.updateBulkOrderStatusToKERRYPrinted(formData);
          if (result.data.result == "ok") {
            // update success
            this.snackbar = {
              active: true,
              msg: `อัพเดตสถานะสำเร็จ`,
            };
          } else {
            // update failed
            this.snackbarFaild = {
              active: true,
              msg: `อัพเดตสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
            };
          }
        }
      }
    },
    doPrint() {
      window.print();
    },
    async getItemsData() {
      let itemsSelected = this.$store.getters["itemsSelected"];
      if (itemsSelected) {
        let ids = _.map(itemsSelected, (item) => {
          return item.id;
        });

        let resultBranches = await api.getBranchActive();
        let branches = resultBranches.data;

        let formData = new FormData();
        formData.append("items", JSON.stringify(ids));
        let result_orders = await api.getOrdersById(formData);
        let result = await api.getProductByIds(formData);

        if (result.data.result == "ok" && result_orders.data.result == "ok") {
          let orderItems = JSON.parse(result.data.message);
          let orderSelected = JSON.parse(result_orders.data.message);
          // console.log(orderItems)
          _.forEach(orderSelected, (order, index) => {
            // order for print
            if (ORDERSTATUS.KERRYEXPORTED == order.status) {
              let branch = _.find(branches, { id: order.ref_branch_id });
              let tracking = _.find(itemsSelected, { id: order.id }).track;

              _.forEach(orderItems, (item) => {
                if (order.id == item.id) {
                  orderSelected[index].products = item.products;
                  orderSelected[index].branch = { ...branch };
                  orderSelected[index].track = tracking;

                  let check_items = item.products.length;

                  if (check_items > 4) {
                    this.orderMoreItems.push(orderSelected[index]);
                  } else {
                    if (check_items == 4 && order.remark_admin != "") {
                      this.orderMoreItems.push(orderSelected[index]);
                    } else {
                      // 1 - 4 ออเดรอ์ and not have remark
                      this.orders.push(orderSelected[index]);
                    }
                  }
                }
              });
              this.ordersPrint.push(order);
            }
          });
          result_orders = null;
          result = null;
          orderItems = null;
          orderSelected = null;
        } else {
          //no adta
        }
      }
    },
  },
  beforeDestroy: function() {
    delete this.orders;
    delete this.orderMoreItems;
    delete this.ordersPrint;
  },
};
</script>

<style scoped>
/* .stylecod {
    padding-top:15px;
} */

.sender_info {
  font-size: 10px;
  white-space: pre-line;
}

ul.ullistdetail li {
  position: relative;
}

ul.ullistdetail li {
  display: block;
  height: 4em;
  /* line-height: 3em; */
  /* border:1px solid red; */
  /*border: 1px solid #ff0000;*/
}

ul.ullistdetail li span {
  display: inline-block;
  vertical-align: middle;
  padding-left: 40px;
  font-size: 12px;
}

ul.ullistdetail li:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

ul.ullistdetail li img {
  position: absolute;
  top: 0px;
  left: -20px;
  width: 58px !important;
  height: 58px !important;
}

/* @media all {
            .page-break {
                display: none;
            }

            .page-break-no {
                display: none;
            }
        } */
@media print {
  .page-break {
    display: block;
    height: 1px;
    page-break-before: always;
  }
  .page-break-no {
    display: block;
    height: 1px;
    page-break-after: avoid;
  }
}

.myfontprint {
  font-family: sans-serif !important;
  font-size: 13px;
}
</style>
